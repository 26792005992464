html {
  direction: rtl;
}

body {
  direction: rtl;
}

.list-unstyled {
  padding-right: 0;
  padding-left: none;
}

.list-inline {
  padding-right: 0;
  padding-left: none;
  margin-right: -5px;
  margin-left: 0;
}

dd {
  margin-right: 0;
  margin-left: none;
}

@media (min-width: 768px) {
  .dl-horizontal dt {
    float: right;
    clear: right;
    text-align: left;
  }

  .dl-horizontal dd {
    margin-right: 180px;
    margin-left: 0;
  }
}
blockquote {
  border-right: 5px solid #eee;
  border-left: 0;
}

.blockquote-reverse,
blockquote.pull-left {
  padding-left: 15px;
  padding-right: 0;
  border-left: 5px solid #eee;
  border-right: 0;
  text-align: left;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  /* min-height: 1px;
  padding-left: 15px;
  padding-right: 15px */
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: right;
}

.col-xs-pull-12 {
  left: 100%;
  right: auto;
}

.col-xs-pull-11 {
  left: 91.66666667%;
  right: auto;
}

.col-xs-pull-10 {
  left: 83.33333333%;
  right: auto;
}

.col-xs-pull-9 {
  left: 75%;
  right: auto;
}

.col-xs-pull-8 {
  left: 66.66666667%;
  right: auto;
}

.col-xs-pull-7 {
  left: 58.33333333%;
  right: auto;
}

.col-xs-pull-6 {
  left: 50%;
  right: auto;
}

.col-xs-pull-5 {
  left: 41.66666667%;
  right: auto;
}

.col-xs-pull-4 {
  left: 33.33333333%;
  right: auto;
}

.col-xs-pull-3 {
  left: 25%;
  right: auto;
}

.col-xs-pull-2 {
  left: 16.66666667%;
  right: auto;
}

.col-xs-pull-1 {
  left: 8.33333333%;
  right: auto;
}

.col-xs-pull-0 {
  left: auto;
  right: auto;
}

.col-xs-push-12 {
  right: 100%;
  left: 0;
}

.col-xs-push-11 {
  right: 91.66666667%;
  left: 0;
}

.col-xs-push-10 {
  right: 83.33333333%;
  left: 0;
}

.col-xs-push-9 {
  right: 75%;
  left: 0;
}

.col-xs-push-8 {
  right: 66.66666667%;
  left: 0;
}

.col-xs-push-7 {
  right: 58.33333333%;
  left: 0;
}

.col-xs-push-6 {
  right: 50%;
  left: 0;
}

.col-xs-push-5 {
  right: 41.66666667%;
  left: 0;
}

.col-xs-push-4 {
  right: 33.33333333%;
  left: 0;
}

.col-xs-push-3 {
  right: 25%;
  left: 0;
}

.col-xs-push-2 {
  right: 16.66666667%;
  left: 0;
}

.col-xs-push-1 {
  right: 8.33333333%;
  left: 0;
}

.col-xs-push-0 {
  right: auto;
  left: 0;
}

.col-xs-offset-12 {
  margin-right: 100%;
  margin-left: 0;
}

.col-xs-offset-11 {
  margin-right: 91.66666667%;
  margin-left: 0;
}

.col-xs-offset-10 {
  margin-right: 83.33333333%;
  margin-left: 0;
}

.col-xs-offset-9 {
  margin-right: 75%;
  margin-left: 0;
}

.col-xs-offset-8 {
  margin-right: 66.66666667%;
  margin-left: 0;
}

.col-xs-offset-7 {
  margin-right: 58.33333333%;
  margin-left: 0;
}

.col-xs-offset-6 {
  margin-right: 50%;
  margin-left: 0;
}

.col-xs-offset-5 {
  margin-right: 41.66666667%;
  margin-left: 0;
}

.col-xs-offset-4 {
  margin-right: 33.33333333%;
  margin-left: 0;
}

.col-xs-offset-3 {
  margin-right: 25%;
  margin-left: 0;
}

.col-xs-offset-2 {
  margin-right: 16.66666667%;
  margin-left: 0;
}

.col-xs-offset-1 {
  margin-right: 8.33333333%;
  margin-left: 0;
}

.col-xs-offset-0 {
  margin-right: 0;
  margin-left: 0;
}

@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: right;
  }

  .col-sm-pull-12 {
    left: 100%;
    right: auto;
  }

  .col-sm-pull-11 {
    left: 91.66666667%;
    right: auto;
  }

  .col-sm-pull-10 {
    left: 83.33333333%;
    right: auto;
  }

  .col-sm-pull-9 {
    left: 75%;
    right: auto;
  }

  .col-sm-pull-8 {
    left: 66.66666667%;
    right: auto;
  }

  .col-sm-pull-7 {
    left: 58.33333333%;
    right: auto;
  }

  .col-sm-pull-6 {
    left: 50%;
    right: auto;
  }

  .col-sm-pull-5 {
    left: 41.66666667%;
    right: auto;
  }

  .col-sm-pull-4 {
    left: 33.33333333%;
    right: auto;
  }

  .col-sm-pull-3 {
    left: 25%;
    right: auto;
  }

  .col-sm-pull-2 {
    left: 16.66666667%;
    right: auto;
  }

  .col-sm-pull-1 {
    left: 8.33333333%;
    right: auto;
  }

  .col-sm-pull-0 {
    left: auto;
    right: auto;
  }

  .col-sm-push-12 {
    right: 100%;
    left: 0;
  }

  .col-sm-push-11 {
    right: 91.66666667%;
    left: 0;
  }

  .col-sm-push-10 {
    right: 83.33333333%;
    left: 0;
  }

  .col-sm-push-9 {
    right: 75%;
    left: 0;
  }

  .col-sm-push-8 {
    right: 66.66666667%;
    left: 0;
  }

  .col-sm-push-7 {
    right: 58.33333333%;
    left: 0;
  }

  .col-sm-push-6 {
    right: 50%;
    left: 0;
  }

  .col-sm-push-5 {
    right: 41.66666667%;
    left: 0;
  }

  .col-sm-push-4 {
    right: 33.33333333%;
    left: 0;
  }

  .col-sm-push-3 {
    right: 25%;
    left: 0;
  }

  .col-sm-push-2 {
    right: 16.66666667%;
    left: 0;
  }

  .col-sm-push-1 {
    right: 8.33333333%;
    left: 0;
  }

  .col-sm-push-0 {
    right: auto;
    left: 0;
  }

  .col-sm-offset-12 {
    margin-right: 100%;
    margin-left: 0;
  }

  .col-sm-offset-11 {
    margin-right: 91.66666667%;
    margin-left: 0;
  }

  .col-sm-offset-10 {
    margin-right: 83.33333333%;
    margin-left: 0;
  }

  .col-sm-offset-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .col-sm-offset-8 {
    margin-right: 66.66666667%;
    margin-left: 0;
  }

  .col-sm-offset-7 {
    margin-right: 58.33333333%;
    margin-left: 0;
  }

  .col-sm-offset-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .col-sm-offset-5 {
    margin-right: 41.66666667%;
    margin-left: 0;
  }

  .col-sm-offset-4 {
    margin-right: 33.33333333%;
    margin-left: 0;
  }

  .col-sm-offset-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .col-sm-offset-2 {
    margin-right: 16.66666667%;
    margin-left: 0;
  }

  .col-sm-offset-1 {
    margin-right: 8.33333333%;
    margin-left: 0;
  }

  .col-sm-offset-0 {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: right;
  }

  .col-md-pull-12 {
    left: 100%;
    right: auto;
  }

  .col-md-pull-11 {
    left: 91.66666667%;
    right: auto;
  }

  .col-md-pull-10 {
    left: 83.33333333%;
    right: auto;
  }

  .col-md-pull-9 {
    left: 75%;
    right: auto;
  }

  .col-md-pull-8 {
    left: 66.66666667%;
    right: auto;
  }

  .col-md-pull-7 {
    left: 58.33333333%;
    right: auto;
  }

  .col-md-pull-6 {
    left: 50%;
    right: auto;
  }

  .col-md-pull-5 {
    left: 41.66666667%;
    right: auto;
  }

  .col-md-pull-4 {
    left: 33.33333333%;
    right: auto;
  }

  .col-md-pull-3 {
    left: 25%;
    right: auto;
  }

  .col-md-pull-2 {
    left: 16.66666667%;
    right: auto;
  }

  .col-md-pull-1 {
    left: 8.33333333%;
    right: auto;
  }

  .col-md-pull-0 {
    left: auto;
    right: auto;
  }

  .col-md-push-12 {
    right: 100%;
    left: 0;
  }

  .col-md-push-11 {
    right: 91.66666667%;
    left: 0;
  }

  .col-md-push-10 {
    right: 83.33333333%;
    left: 0;
  }

  .col-md-push-9 {
    right: 75%;
    left: 0;
  }

  .col-md-push-8 {
    right: 66.66666667%;
    left: 0;
  }

  .col-md-push-7 {
    right: 58.33333333%;
    left: 0;
  }

  .col-md-push-6 {
    right: 50%;
    left: 0;
  }

  .col-md-push-5 {
    right: 41.66666667%;
    left: 0;
  }

  .col-md-push-4 {
    right: 33.33333333%;
    left: 0;
  }

  .col-md-push-3 {
    right: 25%;
    left: 0;
  }

  .col-md-push-2 {
    right: 16.66666667%;
    left: 0;
  }

  .col-md-push-1 {
    right: 8.33333333%;
    left: 0;
  }

  .col-md-push-0 {
    right: auto;
    left: 0;
  }

  .col-md-offset-12 {
    margin-right: 100%;
    margin-left: 0;
  }

  .col-md-offset-11 {
    margin-right: 91.66666667%;
    margin-left: 0;
  }

  .col-md-offset-10 {
    margin-right: 83.33333333%;
    margin-left: 0;
  }

  .col-md-offset-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .col-md-offset-8 {
    margin-right: 66.66666667%;
    margin-left: 0;
  }

  .col-md-offset-7 {
    margin-right: 58.33333333%;
    margin-left: 0;
  }

  .col-md-offset-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .col-md-offset-5 {
    margin-right: 41.66666667%;
    margin-left: 0;
  }

  .col-md-offset-4 {
    margin-right: 33.33333333%;
    margin-left: 0;
  }

  .col-md-offset-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .col-md-offset-2 {
    margin-right: 16.66666667%;
    margin-left: 0;
  }

  .col-md-offset-1 {
    margin-right: 8.33333333%;
    margin-left: 0;
  }

  .col-md-offset-0 {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: right;
  }

  .col-lg-pull-12 {
    left: 100%;
    right: auto;
  }

  .col-lg-pull-11 {
    left: 91.66666667%;
    right: auto;
  }

  .col-lg-pull-10 {
    left: 83.33333333%;
    right: auto;
  }

  .col-lg-pull-9 {
    left: 75%;
    right: auto;
  }

  .col-lg-pull-8 {
    left: 66.66666667%;
    right: auto;
  }

  .col-lg-pull-7 {
    left: 58.33333333%;
    right: auto;
  }

  .col-lg-pull-6 {
    left: 50%;
    right: auto;
  }

  .col-lg-pull-5 {
    left: 41.66666667%;
    right: auto;
  }

  .col-lg-pull-4 {
    left: 33.33333333%;
    right: auto;
  }

  .col-lg-pull-3 {
    left: 25%;
    right: auto;
  }

  .col-lg-pull-2 {
    left: 16.66666667%;
    right: auto;
  }

  .col-lg-pull-1 {
    left: 8.33333333%;
    right: auto;
  }

  .col-lg-pull-0 {
    left: auto;
    right: auto;
  }

  .col-lg-push-12 {
    right: 100%;
    left: 0;
  }

  .col-lg-push-11 {
    right: 91.66666667%;
    left: 0;
  }

  .col-lg-push-10 {
    right: 83.33333333%;
    left: 0;
  }

  .col-lg-push-9 {
    right: 75%;
    left: 0;
  }

  .col-lg-push-8 {
    right: 66.66666667%;
    left: 0;
  }

  .col-lg-push-7 {
    right: 58.33333333%;
    left: 0;
  }

  .col-lg-push-6 {
    right: 50%;
    left: 0;
  }

  .col-lg-push-5 {
    right: 41.66666667%;
    left: 0;
  }

  .col-lg-push-4 {
    right: 33.33333333%;
    left: 0;
  }

  .col-lg-push-3 {
    right: 25%;
    left: 0;
  }

  .col-lg-push-2 {
    right: 16.66666667%;
    left: 0;
  }

  .col-lg-push-1 {
    right: 8.33333333%;
    left: 0;
  }

  .col-lg-push-0 {
    right: auto;
    left: 0;
  }

  .col-lg-offset-12 {
    margin-right: 100%;
    margin-left: 0;
  }

  .col-lg-offset-11 {
    margin-right: 91.66666667%;
    margin-left: 0;
  }

  .col-lg-offset-10 {
    margin-right: 83.33333333%;
    margin-left: 0;
  }

  .col-lg-offset-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .col-lg-offset-8 {
    margin-right: 66.66666667%;
    margin-left: 0;
  }

  .col-lg-offset-7 {
    margin-right: 58.33333333%;
    margin-left: 0;
  }

  .col-lg-offset-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .col-lg-offset-5 {
    margin-right: 41.66666667%;
    margin-left: 0;
  }

  .col-lg-offset-4 {
    margin-right: 33.33333333%;
    margin-left: 0;
  }

  .col-lg-offset-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .col-lg-offset-2 {
    margin-right: 16.66666667%;
    margin-left: 0;
  }

  .col-lg-offset-1 {
    margin-right: 8.33333333%;
    margin-left: 0;
  }

  .col-lg-offset-0 {
    margin-right: 0;
    margin-left: 0;
  }
}
th {
  text-align: right;
}

@media screen and (max-width: 767px) {
  .table-responsive > .table-bordered {
    border: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-right: 0;
    border-left: auto;
  }

  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-left: 0;
    border-right: auto;
  }
}
.radio label,
.checkbox label {
  padding-right: 20px;
  padding-left: auto;
}

.radio input[type=radio],
.radio-inline input[type=radio],
.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox] {
  float: right;
  margin-right: 20px;
  margin-left: 0;
}

.radio-inline,
.checkbox-inline {
  padding-right: 20px;
  padding-left: 0;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-right: 10px;
  margin-left: 0;
}

.has-feedback .form-control {
  padding-left: 42.5px;
  padding-right: auto;
}

.form-control-feedback {
  left: 0;
  right: auto;
}

@media (min-width: 768px) {
  .form-inline .radio,
  .form-inline .checkbox {
    padding-right: 0;
    padding-left: auto;
  }

  .form-inline .radio input[type=radio],
  .form-inline .checkbox input[type=checkbox] {
    margin-right: 0;
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: left;
  }
}
.form-horizontal .has-feedback .form-control-feedback {
  left: 15px;
  right: auto;
}

.caret {
  margin-right: 2px;
  margin-left: 0;
}

.dropdown-menu {
  right: 0;
  left: auto;
  float: left;
  text-align: right;
}

.dropdown-menu.pull-left {
  left: 0;
  float: right;
  right: auto;
}

.pull-left > .dropdown-menu {
  left: 0;
  float: right;
  right: auto;
}

.navbar-nav.pull-left > li > .dropdown-menu,
.navbar-nav > li > .dropdown-menu.pull-left {
  right: auto;
  left: 0;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  float: right;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover,
.btn-group > .btn:focus,
.btn-group-vertical > .btn:focus,
.btn-group > .btn:active,
.btn-group-vertical > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-right: -1px;
  margin-left: 0;
}

.btn-toolbar {
  margin-right: -5px;
  margin-left: 0;
}

.btn-toolbar .btn-group,
.btn-toolbar .input-group {
  float: right;
}

.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
  margin-right: 5px;
  margin-left: 0;
}

.btn-group > .btn:first-child {
  margin-right: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-group > .btn-group {
  float: right;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child > .btn:last-child,
.btn-group > .btn-group:first-child > .dropdown-toggle {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.btn-group > .btn-group:last-child > .btn:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn .caret {
  margin-right: 0;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-right: 0;
}

.input-group .form-control {
  float: right;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.input-group-addon:first-child {
  border-right: 1px solid #ccc;
  border-left: 0;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group-addon:last-child {
  border-left: 1px solid #ccc;
  border-right: 0;
}

.input-group-btn > .btn + .btn {
  margin-right: -1px;
  margin-left: auto;
}

.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
  margin-left: -1px;
  margin-right: auto;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  margin-right: -1px;
  margin-left: auto;
}

.nav {
  padding-right: 0;
  padding-left: auto;
}

.nav-tabs > li {
  float: right;
}

.nav-tabs > li > a {
  margin-left: auto;
  margin-right: -2px;
  border-radius: 4px 4px 0 0;
}

.nav-pills > li {
  float: right;
}

.nav-pills > li > a {
  border-radius: 4px;
}

.nav-pills > li + li {
  margin-right: 2px;
  margin-left: auto;
}

.nav-stacked > li {
  float: none;
}

.nav-stacked > li + li {
  margin-right: 0;
  margin-left: auto;
}

.nav-justified > .dropdown .dropdown-menu {
  right: auto;
}

.nav-tabs-justified > li > a {
  margin-left: 0;
  margin-right: auto;
}

@media (min-width: 768px) {
  .nav-tabs-justified > li > a {
    border-radius: 4px 4px 0 0;
  }
}
@media (min-width: 768px) {
  .navbar-header {
    float: right;
  }
}
.navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
}

.navbar-brand {
  float: right;
}

@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-right: -15px;
    margin-left: auto;
  }
}
.navbar-toggle {
  float: left;
  margin-left: 15px;
  margin-right: auto;
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 25px 5px 15px;
  }
}
@media (min-width: 768px) {
  .navbar-nav {
    float: right;
  }

  .navbar-nav > li {
    float: right;
  }

  .navbar-nav.navbar-right:last-child {
    margin-left: -15px;
    margin-right: auto;
  }

  .navbar-nav.navbar-right.flip {
    float: left !important;
  }

  .navbar-nav.navbar-right .dropdown-menu {
    left: 0;
    right: auto;
  }
}
@media (min-width: 768px) {
  .navbar-text {
    float: right;
  }

  .navbar-text.navbar-right:last-child {
    margin-left: 0;
    margin-right: auto;
  }
}
.pagination {
  padding-right: 0;
}

.pagination > li > a,
.pagination > li > span {
  float: right;
  margin-right: -1px;
  margin-left: 0;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  margin-right: -1px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.pager {
  padding-right: 0;
  padding-left: none;
}

.pager .next > a,
.pager .next > span {
  float: left;
}

.pager .previous > a,
.pager .previous > span {
  float: right;
}

.nav-pills > li > a > .badge {
  margin-left: 0;
  margin-right: 3px;
}

.alert-dismissable {
  padding-left: 35px;
  padding-right: 15px;
}

.alert-dismissable .close {
  top: -2px;
  right: 0;
  left: 21;
}

.progress-bar {
  float: right;
}

.media,
.media-body {
  overflow: hidden;
  zoom: 1;
}

.media > .pull-left {
  margin-right: 10px;
}

.media > .pull-left.flip {
  margin-right: 0;
  margin-left: 10px;
}

.media > .pull-right {
  margin-left: 10px;
}

.media > .pull-right.flip {
  margin-left: 0;
  margin-right: 10px;
}

.media-list {
  padding-right: 0;
  padding-left: auto;
  list-style: none;
}

.list-group {
  padding-right: 0;
  padding-left: none;
}

.list-group-item > .badge {
  float: left;
}

.list-group-item > .badge + .badge {
  margin-ight: 5px;
  margin-left: 0;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-right-radius: 3px;
  border-top-left-radius: 0;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 0;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 3px;
  border-top-left-radius: 0;
}

.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-right: 0;
  border-left: none;
}

.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: none;
  border-left: 0;
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object {
  right: 0;
  left: none;
}

.close {
  float: left;
}

.modal-footer {
  text-align: left;
}

.modal-footer .btn + .btn {
  margin-left: 0;
  margin-right: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-right: -1px;
  margin-left: 0;
}

.modal-footer .btn-block + .btn-block {
  margin-right: 0;
  margin-left: none;
}

.popover {
  right: 0;
  left: none;
  text-align: right;
}

.popover.top > .arrow {
  right: 50%;
  left: none;
  margin-right: -11px;
  margin-left: 0;
}

.popover.top > .arrow:after {
  margin-right: -10px;
  margin-left: 0;
}

.carousel-inner > .item {
  -webkit-transition: .6s ease-in-out right;
  -o-transition: .6s ease-in-out right;
  transition: 0.6s ease-in-out right;
}

.carousel-inner > .active {
  right: 0;
}

.carousel-inner > .next {
  right: 100%;
  left: 0;
}

.carousel-inner > .prev {
  right: -100%;
}

.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  right: 0;
}

.carousel-inner > .active.right {
  left: -100%;
}

.carousel-inner > .active.left {
  right: 100%;
}

.carousel-control {
  right: 0;
  bottom: 0;
}

.carousel-control.left {
  right: auto;
  left: 0;
  background-image: -webkit-linear-gradient(left, color-stop(rgba(0, 0, 0, 0.5) 0), color-stop(rgba(0, 0, 0, 0.0001) 100%));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-repeat: repeat-x;
  /* filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1) */
}

.carousel-control.right {
  left: auto;
  right: 0;
  background-image: -webkit-linear-gradient(left, color-stop(rgba(0, 0, 0, 0.0001) 0), color-stop(rgba(0, 0, 0, 0.5) 100%));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-repeat: repeat-x;
  /* filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1) */
}

.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 50%;
  right: auto;
  margin-right: -10px;
}

.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 50%;
  left: auto;
  margin-left: -10px;
}

.carousel-indicators {
  right: 50%;
  left: 0;
  margin-right: -30%;
  margin-left: 0;
  padding-left: 0;
}

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: 0;
    margin-right: -15px;
  }

  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-left: 0;
    margin-right: -15px;
  }

  .carousel-caption {
    left: 20%;
    right: 20%;
    padding-bottom: 30px;
  }
}
.pull-right.flip {
  float: left !important;
}

.pull-left.flip {
  float: right !important;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #3c763d;
}

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}

.has-success .form-control-feedback {
  color: #3c763d;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #8a6d3b;
}

.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b;
}

.has-warning .form-control-feedback {
  color: #8a6d3b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #a94442;
}

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}

.has-error .form-control-feedback {
  color: #a94442;
}

html[dir=rtl] {
  letter-spacing: 0;
}

[dir=rtl] body {
  letter-spacing: 0;
}
[dir=rtl] .yamm .mainNav > li.searchButton > a {
  transform: rotate(219deg);
}
[dir=rtl] .logoRight {
  float: right;
}
[dir=rtl] .navbar-header {
  float: left;
}
[dir=rtl] .slick-arrow {
  direction: ltr;
}
[dir=rtl] .slick-list {
  direction: rtl;
}
[dir=rtl] .slick-track {
  float: left;
  float: none;
}
[dir=rtl] .slick-next {
  right: 0;
  left: auto;
}
[dir=rtl] .slick-prev {
  left: 0;
  right: auto;
}
[dir=rtl] .socialMediaWrap {
  left: 0;
  right: auto;
}
[dir=rtl] .slick-next:before {
  content: "\67";
}
[dir=rtl] .slick-prev:before {
  content: "\66";
}
[dir=rtl] .logoRight {
  float: left;
  margin-left: 0;
}
[dir=rtl] .languageSwitch {
  height: 36px;
  line-height: 1.1;
}
[dir=rtl] .navbar-header {
  float: right;
}
[dir=rtl] .yamm .mainNav > li.langSwitch > a {
  letter-spacing: 0;
}
[dir=rtl] .homeBanner {
  background: url("../images/banner-bg_ar.png") right center no-repeat;
}
[dir=rtl] .buttonsWrap .circle-btn, [dir=rtl] .buttonsWrap .circleBtn {
  margin-right: 0;
  margin-left: 20px;
}
[dir=rtl] .buttonsWrap .circle-btn:last-child, [dir=rtl] .buttonsWrap .circleBtn:last-child {
  margin-left: 0;
}
[dir=rtl] .aboutSection {
  background-position: left center;
}
[dir=rtl] .sectionTitleWrap h2:after, [dir=rtl] .sectionTitleWrap h2:before {
  left: auto;
  right: 3px;
}
[dir=rtl] .missionLeftWrap::before {
  right: auto;
  left: -34px;
}
[dir=rtl] .missionRightWrap .iconsListWrap li {
  margin-right: 0px;
  margin-left: 80px;
}
[dir=rtl] .slick-slide {
  float: left;
}
[dir=rtl] .processSection .processLeftWrap::before {
  right: auto;
  left: 0;
}
[dir=rtl] .centeTitleWrap .sectionTitleWrap h2:after, [dir=rtl] .centeTitleWrap .sectionTitleWrap h2:before {
  left: 0;
  right: 0;
}
[dir=rtl] .categoriesSection #tab-1 .percentage-spinner:before, [dir=rtl] .categoriesSection #tab-1 .percentage-spinner:after {
  left: auto;
  right: 12.5%;
}
[dir=rtl] .categoriesSection .percentage-spinner:after {
  left: auto;
  right: 10%;
}
[dir=rtl] .buttonsWrap {
  direction: rtl !important;
}
[dir=rtl] .initiativesSliderWraper .initiativesWrp .inImgWrap {
  float: right;
}
[dir=rtl] .initiativesSliderWraper .initiativesWrp .inDescWrap {
  float: right;
}
[dir=rtl] .initiativesSliderWraper .initiativesSlider .inDescWrap {
  transform: translateY(50px) translateX(52px);
  text-align: right;
  padding-left: 22px;
  padding-right: 82px;
}
[dir=rtl] .initiativesSliderWraper .initiativesSlider .slide.slick-active .inDescWrap {
  transform: translateY(30px) translateX(52px);
}
[dir=rtl] .initiativesSliderWraper .initiativesSlider .slick-arrow {
  right: 0;
  left: auto;
}
[dir=rtl] .initiativesSliderWraper .initiativesSlider .slick-arrow.slick-next {
  right: -49px;
}
[dir=rtl] .initiativesWrp {
  flex-direction: row-reverse;
}
[dir=rtl] .contentContainer .recaptcha, [dir=rtl] .contentContainer .g-recaptcha {
  transform-origin: top right;
}
[dir=rtl] .ui-selectmenu-text {
  text-align: right;
  margin-right: 0;
  margin-left: 20px;
}
[dir=rtl] .ui-selectmenu-icon.ui-icon {
  float: left;
}
[dir=rtl] .newsLetterWrap .newsSuscribeForm input.inputFeild {
  padding-right: 10px;
  padding-left: 50px;
}
[dir=rtl] .newsLetterWrap .newsSuscribeForm input.button {
  left: 0;
  right: auto;
  border-left: none;
  border-right: 1px solid #ffdedc;
}

.imageTextBlockContainer .imageTextBlockWrapper:nth-child(even) .textBlock:before, .imageTextBlockContainer .imageTextBlockWrapper:nth-child(even) .textBlock:after {
  left: auto;
  right: 0;
}

.popup ul li {
  padding-left: 0px;
  padding-right: 40px;
}
.popup ul li::before {
  right: 0;
  left: auto;
}

.quotesWrap {
  flex-direction: row;
}

.tab-content .noLevels p {
  padding-left: 0;
  padding-right: 1.5%;
}

.dashboardWrapper .feildWrap .column {
  float: left;
}
.dashboardWrapper .feildWrap .list-block li {
  padding-left: 0;
  padding-right: 40px;
}
.dashboardWrapper .feildWrap .list-block li::before {
  right: 0;
  left: auto;
}

.myProfileWrap .listWrap > li label {
  float: right;
}
.myProfileWrap .listWrap > li .pullRight {
  float: left;
}

.circleBtn svg {
  transform: rotate(180deg);
  display: inline-block;
  transform-origin: center center;
}

.slick-slider {
  direction: ltr !important;
}

.contentContainer input[type=checkbox]:not(old) + label > span, .contentContainer input[type=radio]:not(old) + label > span {
  margin-right: 0;
  margin-left: 11px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  html[dir=rtl] .homeBanner .container > .row .bannerLeft .sloganWrap {
    left: auto;
    right: 0;
  }
}
@media only screen and (min-width: 769px) {
  .categorySlider .slick-slide {
    float: right;
  }

  .aboutHeaderSection .tagLine p::before {
    transform: translateX(121px);
  }

  .shadeRight::before {
    right: auto;
    left: -15px;
  }
}
@media only screen and (max-width: 1024px) {
  .navbar-collapse {
    width: 100%;
  }
}
@media only screen and (max-width: 1025px) {
  html[dir=rtl] .navbar-header {
    width: 100%;
  }
  html[dir=rtl] .mobileLogoWrap {
    float: right;
  }
  html[dir=rtl] .mobileLogoWrap a {
    width: auto;
    left: auto;
    right: 0;
  }
}
@media only screen and (min-width: 1050px) {
  .imageTextBlockWrapper .textBlock .buttonsWrap .contactMailBtn {
    float: left;
  }

  .homeBanner .container > .row .bannerLeft .sloganWrap {
    left: auto;
    right: 0;
  }

  .homeBanner .container > .row .bannerLeft .sloganWrap h1 {
    line-height: 1.3;
  }
}
@media only screen and (max-width: 767px) {
  html[dir=rtl] .initiativesSlider .slick-arrow {
    top: 25%;
  }
  html[dir=rtl] .initiativesSlider .slick-arrow.slick-prev {
    left: 0;
    right: auto;
  }
  html[dir=rtl] .initiativesSlider .slick-arrow.slick-next {
    left: auto;
    right: 0;
  }

  [dir=rtl] .initiativesSliderWraper .initiativesSlider .slick-arrow.slick-next {
    right: 0;
  }
}
@media only screen and (max-width: 768px) {
  [dir=rtl] .initiativesSliderWraper .initiativesSlider .slide .inDescWrap {
    transform: none;
    padding: 15px 20px;
    padding-top: 34px;
  }
}
/* iPads (portrait) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  [dir=rtl] .initiativesSliderWraper .initiativesSlider .slide.slick-active .inDescWrap {
    transform: none !important;
  }

  .initiativesSlider .slick-arrow {
    top: 25% !important;
  }
}
@media only screen and (max-width: 767px) {
  .homeBanner .container > .row .bannerLeft .sloganWrap h1 {
    line-height: 1.5;
  }
}
@media only screen and (max-width: 990px) {
  .initiativesSlider .slick-arrow {
    top: 25%;
  }
  .initiativesSlider .slick-arrow.slick-prev {
    left: 0 !important;
    right: auto !important;
  }
  .initiativesSlider .slick-arrow.slick-next {
    left: auto !important;
    right: 0 !important;
  }
}
@media only screen and (min-width: 991px) {
  html[dir=rtl] .navbar-header {
    float: left;
  }
  html[dir=rtl] .logoRight {
    float: right;
  }

  .missionRightWrap {
    padding-left: 20px;
    padding-right: 10%;
  }

  .imageTextBlockWrapper .textBlock .blockTitle:after {
    left: auto;
    right: 0;
  }

  .imageTextBlockContainer .imageTextBlockWrapper:nth-child(odd) {
    flex-direction: row;
  }
  .imageTextBlockContainer .imageTextBlockWrapper:nth-child(odd) .textBlock {
    padding-left: 45px;
    padding-right: 120px;
  }
  .imageTextBlockContainer .imageTextBlockWrapper:nth-child(even) {
    flex-direction: row-reverse;
  }
  .imageTextBlockContainer .imageTextBlockWrapper:nth-child(even) .textBlock {
    padding-right: 45px;
    padding-left: 120px;
  }
}


